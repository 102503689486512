import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/";


export const getInvestorInformation = (email: string, page: number, ca_doc_sent_status: string, profile_type: string) => {
  const params: { [key: string]: string | number | boolean | undefined } = {
    email: email,
    page: page,
    ca_doc_sent_status: ca_doc_sent_status,
    profile_type: profile_type,
  };
  const queryString = Object.entries(params)
    .reduce<string[]>((acc, [key, val]) => {
      if (val != null && val != "") {
        acc.push(`${key}=${encodeURIComponent(String(val))}`);
      }
      return acc;
    }, [])
    .join('&');
  const finalURL = 
    // `http://127.0.0.1:8000` + `/v1/investor_informations/get_admin_investor_informations/?email=${search}&page=${page}&ca_doc_sent_status=${ca_doc_sent_status}&profile_type=${selectedType}`
    API_BASE_URL + `/v1/investor_informations/get_admin_investor_informations/?${queryString}`
    // `v1/admin/user_management/non_sebi_investors/?search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getNomineeInformation = (email: string, page: number) => {
  const params: { [key: string]: string | number | boolean | undefined } = {
    email: email,
    page: page,
  };
  const queryString = Object.entries(params)
    .reduce<string[]>((acc, [key, val]) => {
      if (val != null && val != "") {
        acc.push(`${key}=${encodeURIComponent(String(val))}`);
      }
      return acc;
    }, [])
    .join('&');
  const finalURL = 
    API_BASE_URL + `/v1/investor_informations/nominee_manager/?${queryString}`
    // `v1/admin/user_management/non_sebi_investors/?search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getDematInformation = (email: string, page: number, tab: string) => {
  const params: { [key: string]: string | number | boolean | undefined } = {
    email: email,
    page: page,
    tab: tab
  };
  const queryString = Object.entries(params)
    .reduce<string[]>((acc, [key, val]) => {
      if (val != null && val != "") {
        acc.push(`${key}=${encodeURIComponent(String(val))}`);
      }
      return acc;
    }, [])
    .join('&');
  const finalURL = 
    API_BASE_URL + `/v1/investor_informations/demat_manager/?${queryString}`
    // `v1/admin/user_management/non_sebi_investors/?search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};
